$primary-color1: #000000;
$primary-color2: rgba(0, 0, 0, 0.5);
$secondary-color:#1C3988;
$default-radius: 8px 8px 0px 0px;
$divider-color: rgba(53, 45, 59, 0.5);

@mixin default-fonts{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}

@mixin other-fonts($family,$style,$weight,$size,$line-height){
    font-family: $family;
    font-style: $style;
    font-weight: $weight;
    font-size: $size;
    line-height: $line-height;
}

@mixin flex-containers($direction,$align){
    display: flex;
    flex-direction: $direction;
    align-items: center;
}

@mixin forSaleContent-containers{
    @include flex-containers(column, center);
    width: 98%;
    padding: 0px;
    margin:0px auto;
    margin-top:20px;
    background: #FFFFFF;
    border-radius: $default-radius;
}

@mixin component-headers{
    @include flex-containers(row, center);
    padding-left:10px;
    width: calc(100% - 10px);
    height: 41px;
    background: $secondary-color;
    border-radius: $default-radius;
    @include default-fonts;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
}

@mixin component-content{
    padding-top:20px;
    padding-bottom: 20px;
    padding-left: 10px;
    width:95%;
    margin:0px auto;
}

//gallery
$gallery-color: #081F32;

//location price changes
$sales-price-color: #E75353;
$rental-price-color: #9FCBFF;