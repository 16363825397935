@import './variables.scss';

.pathBoxContainer{
    width:90%;
    height: 16px;
    @include fonts('Roboto',normal,400,14px,16px);
    color: rgba(0, 0, 0, 0.5);
    margin:0 auto;
    margin-top:80px;
}

.filterContainer-desktop{
    background: $background-color1;
    width:100%;
    height: 332px;
    margin:0px auto;
    margin-top:30px;
    padding: 0px 50px;

    .searchBarContainer{
        position: relative;
        margin:0px auto;
        top:20px;
        width:100%;
        height: 49px;
        background: $background-color1;

        .saleExpandBox{
            background: $background-color2;
            border: 1px solid $secondary-color1;
            box-sizing: border-box;
            box-shadow: inset 0px 1px 5px rgba(0, 0, 0, 0.1);
            border-radius: $default-border-radius;
            min-width:100px;
            height:49px;
            @include flex-containers(row);
            cursor: pointer;

            .expandOption{
                @include fonts('Open Sans',normal,400,17px,27px);
                color: $secondary-color1;
                width:70px;
                margin-left: 16px;
            }

            .expandIcon{
                margin-left:auto;
                margin-right:20px;
            }
        }

        .searchBox{
            height: 49px;
            @include flex-containers(row);

            &::placeholder,.searchBar{
                height: 49px;
                width:100%;
                padding: 8px 16px;
                background: $background-color2;
                border: 1px solid $secondary-color1;
                box-sizing: border-box;
                border-radius: $default-border-radius;
            }
        }

        .searchButton{
            min-width: 120px;
            height: 49px;
            background: #1C3988;
            border-radius: $default-border-radius;
            @include flex-containers(row);
            cursor: pointer;

            .searchIcon{
                margin-left:24px;
                width: 16px;
                height: 14px;
            }

            .searchText{
                margin-left: 10px;
                margin-right:40px;
                @include fonts('Open Sans',normal,700,16px,22px);
                text-align: center;
                color: $background-color2;
            }
        }
    }

    .divider{
        @include fonts('Open Sans',normal,400,16px,34px);
        color: $secondary-color1;
    }

    .filterSettingsContainer{
        height:261px;
        width:100%;
        
        .filterBox-desktop{
            width:100%;
            margin:0px auto;   
            @include flex-containers(row);
    
            .filterButtons-desktop{
                @include flex-containers(row);
                justify-content: flex-start;
                padding: 0px;
                width: 1174px;
                height: 52px;
                
                .filterButton-desktop{
                    width: 110px;
                    height: 52px;
                    padding:16px 0px;
                    margin-left:4px;
                    @include flex-containers(row);
                    text-align: center;
                    background: rgba(255, 255, 255, 0.4);
                    border-radius: 20px 20px 0px 0px;
                    @include default-font;
                    color: $secondary-color2;
                    cursor: pointer;
                }
        
                .activeChoice{
                    background: $background-color2;
                    color: #110229;
                    margin-left:0px;
                }
            }
    
            .clearButton{
                width: 102px;
                height: 52px;
                @include flex-containers(row);
                text-align: center;
                @include default-font;
                color: #110229;
                margin-left: auto;
                cursor: pointer;
            }
        }
    
        .filterOptions{
            height: 120px;
            background: rgba(255, 255, 255, 0.9);
            box-shadow: 0px 30px 60px -15px rgba(143, 144, 188, 0.15);
            backdrop-filter: blur(20px);
            border-radius: 0px 20px 20px 20px;
            margin:0px auto;
        }
    }
    
    .cities-filter-desktop{
        height: 115px;
        overflow-y: scroll;
    
        &::-webkit-scrollbar{
            width:5px;
            border-radius: $default-border-radius;
            background-color: rgba(143, 144, 166, 0.1);
        }
    
        &::-webkit-scrollbar-thumb{
            background: $secondary-color2;
            border-radius: $default-border-radius;
        }
    
        .filter-row{
            padding-top:16px;
            padding-left: 34px;
    
            .filter-item{
                height: 20px;
                width:fit-content;
                @include default-font;
                text-transform: none;
                color: $secondary-color2;
                @include flex-containers(row);

                .availableNumber{
                    color: rgb(95, 32, 212);
                    margin-left:3px;
                }
            }
        }
    }

    .propertyType-desktop{
        .filter-row{
            padding-top:16px;
            padding-left: 34px;
    
            .filter-item{
                height: 20px;
                width:fit-content;
                @include default-font;
                text-transform: none;
                color: $secondary-color2;
            }
        }
    }
}

.filterContainer-mobile{
    min-width:320px;
    width:80%;
    margin:0px auto;
    margin-top:70px;
    margin-bottom: 20px;

    .searchBox-mobile{
        @include flex-containers(row);
        justify-content: flex-start;
        padding: 0px;
        min-width: 320px;
        width:100%;
        height: 41px;
        background: $background-color2;
        border: 1px solid #000000;
        border-radius: $default-border-radius;

        &::placeholder,.searchBar-mobile{
            border: none;
            padding: 8px 16px;
        }

        .searchIcon-mobile{
            color:$secondary-color2;
            width:30px;
            height:30px;
            padding-left:12px;
        }
    }

    .filterBox-mobile{
        @include flex-containers(row);
        min-width: 320px;
        width:100%;
        height: 40px;
        margin-top: 16px;
        border: 1px solid #000000;
        background: $background-color1-mobile;
        border-radius: $default-border-radius;
        @include secondary-font;
        color: $secondary-color1;

        .filterButton-mobile{
            cursor: pointer;
            @include flex-containers(row);
            height: 40px;
            box-shadow: inset -1px 0px 0px rgba(0, 0, 0, 0.5);

            .filterIcon-mobile{
                width:20px;
                padding-right:5px;
            }
        }

        .sortButton{
            cursor: pointer;
            @include flex-containers(row);
            height: 40px;

            .sortIcon{
                width:20px;
                padding-right:5px;
            }
        }
    }
}

.drawerFilterContainer{
   width:100%;
}

.drawerContainer{
    min-width:360px;
    width:100%;
    height:100%;
    margin: 0px auto;

    .closeButton{
        position: fixed;
        right:20px;
        top:20px;

        .closeIcon{
            width:20px !important;
            height:20px !important;
            color:#000000;

            @media only screen and (min-width:600px) {
                width:30px !important;
                height:30px !important;
            }
        }
    }

    .rent-buy-box{
        margin:0px auto;
        margin-top:60px;
        min-width: 320px;
        width:80%;
        height: 32px;
        @include flex-containers(row);
        align-items: flex-start;
        padding: 0px;
        background: $background-color2;
        @include default-font;

        .rentButton{
            background: rgba(255, 255, 255, 0.4);
            color: $secondary-color2;
            width: 158px;
            height: 32px;
            border-radius: 20px 20px 0px 0px;
            cursor: pointer;
            @include flex-containers(row);

            .rent{
                width: 31px;
                height: 20px;
                text-align: center;
            }
        }

        .buyButton{
            width: 158px;
            height: 32px;
            background: rgba(255, 255, 255, 0.4);
            color: $secondary-color2;
            border-radius: 20px 20px 0px 0px;
            cursor: pointer;
            @include flex-containers(row);

            .buy{
                width: 25px;
                height: 20px;
                text-align: center;
            }
        }

        .activeChoice{
            color:#000000;
        }
    }

    .selectCityBox-mobile{
        margin:0px auto;
        margin-top:20px;
        @include flex-containers(row);
        min-width: 320px;
        width:80%;
        border-radius: $default-border-radius;
        border: 1px solid $secondary-color2;

        .selectText{
            @include secondary-font;
            font-weight: 500;
            font-size: 15px;
            color: $secondary-color2;
            padding: 8px 16px;
        }

        .expandIcon-drawer{
            margin-left: auto;
            width: 12px;
            margin-right: 20px;
        }
    }

    .searchBox-drawer{
        @include flex-containers(row);
        padding: 0px;
        margin:0px auto;
        margin-top:12px;
        min-width: 320px;
        width:80%;
        height: 41px;
        background: $background-color1-mobile;

        &::placeholder,.searchBar-drawer{
            border: none;
            min-width: 264px;
            width:90%;
            background: $background-color1-mobile;
            @include secondary-font;
            @include flex-containers(row);
            color: $secondary-color2;
        }

        .searchIcon-drawer{
            width: 20px;
            height: 20px;
            margin-left:12px;
            margin-right:12px;
        }
    }

    .filterHeader-mobile{
        @include secondary-font;
        font-weight: 500;
        font-size: 15px;
        color: #1B1B1A;
    }

    .filterItemsGrid{
        margin-top: 14px;

        .filterItem{
            @include flex-containers(row);
            border: 1px solid #1B1B1A;
            box-sizing: border-box;
            border-radius: $default-border-radius;
            @include secondary-font;
            padding: 6px 12px;
        }
    }

    .propertyType{
        margin:0px auto;
        margin-top: 20px;
        @include flex-containers(column);
        align-items: flex-start;
        padding: 0px;
        min-width: 320px;
        width:80%;

        .filterItemsGrid{
            .rent{
                min-width: 96px;
                width:30%;
                height: 32px;
                padding: 6px 6px;

                @media only screen and (min-width:600px){
                    min-width:156px;
                }
            }

            .buy{
                height: 32px;
                width:48%;

                @media only screen and (min-width:600px){
                    min-width:236px;
                }
            }
        }
    }

    .bedrooms{
        margin:0px auto;
        margin-top: 20px;
        @include flex-containers(column);
        align-items: flex-start;
        padding: 0px;
        min-width: 320px;
        width:80%;

        .filterItemsGrid{
            margin-top: -4px;

            .filterItem{
                margin-top:16px;
                padding: 6px 12px;
                max-width: 96px;
            }
        }
    }

    .budget{
        margin:0px auto;
        margin-top: 20px;
        @include flex-containers(column);
        align-items: flex-start;
        padding: 0px;
        min-width: 320px;
        width:80%;

        .budgetSlider{
            color: #1C3988;

            .thumb{
                background: $background-color2 !important;
                border: 2px solid #1C3988;
            }
        }
    }

    .availability{
        margin:0px auto;
        margin-top: 20px;
        @include flex-containers(column);
        align-items: flex-start;
        padding: 0px;
        min-width: 320px;
        width:80%;

        .filterItemsGrid{
            margin-top: -4px;

            .filterItem{
                margin-top:16px;
                padding: 6px 12px;
                max-width: 152px;

                @media only screen and (min-width:600px){
                    max-width:140px;
                }
            }
        }
    }

    .furnishingStatus{
        margin:0px auto;
        margin-top: 20px;
        @include flex-containers(column);
        align-items: flex-start;
        padding: 0px;
        min-width: 320px;
        width:80%;

        .filterItemsGrid{
            .filterItem{
                padding: 6px 0px;
                min-width: 96px;
                width:32.5%;

                @media only screen and (min-width:600px){
                    min-width:156px;
                }
            }
        }
    }

    .filterItemButton{
        color:black;
        text-transform: none;
        @include secondary-font;
        line-height: 15px;
    }
    
    .viewAllButton{
        cursor:pointer;
        margin:0px auto;
        @include flex-containers(row);
        min-width: 344px;
        width:80%;
        height: 47px;
        background: #1C3988;
        border-radius: $default-border-radius;
        @include fonts('DM Sans',normal,700,18px,23px);
        color: $background-color2;
        position: fixed;
        bottom:20px;
        left:0%;
        right:0%;
    }
}