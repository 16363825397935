@import './variables.scss';

.heading{
    @include component-headers;
}

.content{
    @include component-content;
}

.pathBoxContainer{
    width:100%;
    height: 16px;
    @include default-fonts;
    color: $primary-color2;
    margin:0 auto;
    margin-top:20px;
}

.galleryContainer{
    @include forRentContent-containers;
    width:100%;
    height:auto;

    .leftAndRightContainer{
        width:100% !important;
        
        .leftContainer{
            width:100%;
        }

        .rightContainer{
            padding-left: 20px;
            padding-right: 20px;
            margin:auto;

            .propertyLocation{
                width:100%;
                @include other-fonts('DM Sans',normal,400,24px,31px);
                color: $gallery-color;

            }

            .propertyName{
                height: 31px;
                margin-top: 8px;
                @include other-fonts('SF Pro Text',normal,700,24px,29px);
                color: #374A59;
                width:100%;
            }
            
            .listingID{
                margin-top:8px;
                width:100%;
                @include other-fonts('SF Pro Text',normal,400,14px,17px);
                color: $primary-color1;
            }
            .propertyFeatures{
                width:100%;
                margin-top:8px;

                .featureItem{
                    @include flex-containers(row);
                    padding-top: 16px;
                    padding-bottom: 16px;

                    @media (max-width:960px){
                        padding-top: 4px;
                        padding-bottom: 4px;
                    }
                    .featureIcon{
                        height:14px;
                    }
                    .featureDescr{
                        margin-left: 4px;
                        @include default-fonts;
                        color: $primary-color1;
                    }
                }
            }
            .interestedBuyers{
                width:100%;
                margin-top:16px;
                @include default-fonts;
                font-size: 10px;
                line-height: 12px;
                color: #9672FF;
            }
            .propertyDetails{
                width:100%;
                margin-top: 16px;
                @include flex-containers(row);
                padding: 8px 0px;

                .detailsItem{
                    @include flex-containers(column);
                    align-items: center;
                    padding:0px 10px;

                    .detailsValue{
                        @include other-fonts('SF Pro Text',normal,700,18px,21px);
                        color: $gallery-color;
                    }

                    .detailsDesc{
                        @include other-fonts('SF Pro Text',normal,400,12px,14px);
                        color: $gallery-color;
                    }
                }

                .divider{
                    background-color:$divider-color !important;
                }
            }
            .interestedButton{
                width:95%;
                height: 47px;
                @include flex-containers(row);
                justify-content: center;
                margin:0px auto;
                margin-top: 16px;              
                background: $secondary-color;
                color: #FFFFFF;
                @include other-fonts('DM Sans',normal,600,18px,23px);
                border-radius: 8px;
                cursor: pointer;
            }
        }
    }
}

.projectInfoContainer{
    @include forRentContent-containers;
    padding-bottom: 69px;

    .content{
        color: $primary-color2;

        .contentDesc{
            @include other-fonts('Roboto',normal,400,16px,18.75px);

            .listItems{
                margin: 0px;
                margin-left: -10px;
            }
        }

        .contentHeader{
            @include other-fonts('Roboto',normal,700,16px,18.75px);
            padding:20px 0px;
        }
    }
}

.amenitiesContainer{
    @include forRentContent-containers;

    .content{
        .amenitiesRow{
            .amenitiesItem{
                @include other-fonts('Roboto',normal,400,18px,21px);
                color:$primary-color1;
                @media (max-width:600px){
                    font-size: 13px;
                    line-height: 15px;
                }
            }

            .amenitiesList{
                height:60px;
                @include default-fonts;
                color:$primary-color2;
                @media (max-width:600px){
                    height:80px;
                }

                .amenitiesListItem{
                    padding-bottom: 8px;
                    display:flex;
                    justify-content: flex-start;
                    width:50%;

                    .tickMark{
                        padding-right:10px;
                        width:10px;
                    }
                }
            }

            .firstList{
                height:132px;
                @media (max-width:600px){
                    height:170px;
                }
            }
        }

        .divider{
            background-color:$divider-color !important;
            margin-bottom: 20px;
        }
    }
}

.specificationsContainer{
    @include forRentContent-containers;

    .content{
        padding-bottom: 0px;
        .specificationsRow{
            .specificationsItem{
                @include default-fonts;
                color:$primary-color2;
            }

            .specificationsList{
                @include default-fonts;
                color:$primary-color1;
                padding-left:10px;

                .specificationsListItem{
                    width:40%;
                    padding-bottom: 20px;
                    padding-right:10px;
                    @media (max-width:600px)
                    {
                        width:100%;
                    }
                }
            }
        }
    }
}

.locationInfoContainer{
    @include forRentContent-containers;
    padding-bottom: 20px;

    .content{
        .mapImage{
            width:100%;
            z-index:-1000;
        }

        .zoomButtons{
            position: relative;
            margin-top: -90px;
            margin-left: 95%;

            @media (max-width:960px){
                margin-left: 93%;
            }
            
            .zoominButton{
                z-index: 1000;
                width:30px;
                height:30px;
                background: #FFFFFF;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                border-radius: 5px;
                @include flex-containers(column);
                justify-content: center;
                font-size:24px;
                cursor: pointer;
            }

            .zoomoutButton{
                width:30px;
                height:30px;
                margin-top:10px;
                background: #FFFFFF;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                border-radius: 5px;
                display: flex;
                @include flex-containers(column);
                font-size:24px;
                cursor: pointer;
            }
        }
    }
}

.priceChangesContainer{
    @include forRentContent-containers;

    .subHeading{
        margin-left:auto;
        padding-right:10px;
        @include default-fonts;
    }

    @media (max-width:600px){
        .heading{
            display: unset;
            padding-top: 10px;
            padding-bottom: 14px;
        }
        .subHeading{
            padding-top: 4px;
        }
    }

    .content{
        .priceGrid{
            margin-top: -20px;

            .priceCategory{
                @include default-fonts;
                color: $primary-color1;
                margin-top:20px;
            }
    
            .yearsTab{
                @include flex-containers(row);
                padding: 0px;
                gap: 28px;
                margin-top:24px;
        
                .yearItem{
                    @include default-fonts;
                    color: $primary-color2;
                    padding:5px;
                    cursor: pointer;
                }
        
                .activeSalesItem{
                    color: $sales-price-color;
                    border: 1px solid $sales-price-color;
                    border-radius: 5px;
                }
        
                .activeRentalItem{
                    color:$rental-price-color;
                    border:1px solid $rental-price-color;
                    border-radius: 5px;
                }
            }
        }
    }

    .graphContainer{
        margin-top:24.5px;
        .graphItem{
            margin-bottom:20px;
            .streetNumber{
                @include default-fonts;
                margin-bottom: 10px;
            }
        }
    }

    .graphParent{
        height: 25px;
        width: 90%;
        border-radius: 5px;
        overflow: hidden;

        .graphChild{
            height:100%;
            border-radius:5px;
            text-align: right;

            .graphText{
                @include default-fonts;
                color: #FFFFFF;
                margin-right:8px;
            }
        }
        .sales{
            background-color: $sales-price-color;
        }
        .rental{
            background-color: $rental-price-color;
        }
    }
    .sales{
        border: 1px solid $sales-price-color;
    }
    .rental{
        border: 1px solid $rental-price-color;

    }
}

.aboutProjectsContainer{
    @include forRentContent-containers;

    .content{
        width:98%;

        .projectsContainer{

            .projectsImageContainer{
                .projectsImage{
                    width:100%;
                }
            }
    
            .projectsDescriptionContainer{
                @include default-fonts;
                font-size:16px;
                line-height: 19px;
                color: $primary-color2;
                padding-right:10px;

                .boldText{
                    font-weight: 700;
                }
            }
        }

        .showLessContainer{
            margin-top:32px;
            @include flex-containers(row);
            justify-content: center;

            .showLessText{
                @include other-fonts('Open Sans',normal,400,17px,27px);
                color: #141B2D;
                cursor: pointer;
            }

            .expandIcon{
                width: 12px;
                height: 7px;
                padding-left:8px;
                cursor: pointer;
            }
        }
    }
}

.disclaimerContainer{
    @include forRentContent-containers;

    .content{
        @include default-fonts;
        font-size:16px;
        line-height: 19px;
        color: $primary-color2;
    }
}

.activityContainer{
    @include forRentContent-containers;

    .content{
        .activityDetails{
            padding:18.5px 20px;

            .activityItem{
                @include flex-containers(column);
        
                .activityValue{
                    @include other-fonts('SF Pro Text',normal,700,32px,38px);
                    color: #081F32;
                }
        
                .activityDesc{
                    @include other-fonts('SF Pro Text',normal,400,12px,14px);
                    color: #081F32;
                }
            }

            .divider{
                background-color:$divider-color !important;
            }
        }
    }
}

.offerContainer{
    @include forRentContent-containers;
    border: 1px solid #119D30;
    border-radius: 16px;

    .offerCardImageContainer{
        .offerCardImage{
            width: 137px;
            height:109px;
            margin:20px;
        }
    }

    .offerCardItems{
        @include flex-containers(column);
        justify-content: center;

        .offerCardItem{
            width:100%;
            @include flex-containers(row);
            align-items: left;
            padding:9px 0px;

            .offerCardItem-image{
                width:24px;
                height:24px;
                margin:0px 8px;
            }

            .normalText{
                @include other-fonts('Open Sans',normal,400,14px,19px);
                color: #222121;
            }

            .boldText{
                @include other-fonts('Open Sans',normal,700,14px,19px);
                color: #222121;
                margin:0px 3px;
            }
        }
    }
}