@import url(//db.onlinewebfonts.com/c/1c45e28f8e86cc89876f003b953cc3e9?family=SF+Pro+Text);
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,700;1,500&display=swap");

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.contactUsBg {
  background: #f2ede6;
  width: 100%;
  margin-top: 50px;

  .uploadContainer {
    width: 80%;
    margin: 0 auto;

    .uploadTitleContainer {
      margin-bottom: 30px;
      .uploadTitle {
        font-family: "SF Pro Text";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        //line-height: 64px;
        color: #000000;
      }
    }

    .uploadBox {
      margin-bottom: 30px;
      padding: 20px 0;
      border: 2px dashed #352d3b;
      .uploadImg {
        padding-bottom: 20px;
      }
      .addFileBtn {
        padding: 5px 15px;
        background: #1c3988;
        border: 1px solid #1c3988;
        border-radius: 8px;
        font-family: "SF Pro Text";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #ffffff;
      }
      .dropTitle {
        padding-top: 20px;
        font-family: "SF Pro Text";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
      }
    }

    .contactBtnContainer {
      display: flex;
      justify-content: center;
      .contactBtn {
        outline: none;
        border: none;
        cursor: pointer;
        width: 50%;
        background: #e02828;
        border-radius: 100px;
        // margin-top: 25px;
        margin-bottom: 20px;
        padding: 10px 0 10px 0;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 15px;
        color: #ffffff;

        @media (max-width: 900px) {
          width: 100%;
        }
      }
    }
  }
}
.contactUsContainer {
  padding: 20px 0 20px 0;
  .leftContainer {
    display: flex;
    justify-items: center;
    .contactUsImg {
      width: 100%;
      padding-right: 30px;
      height: 100%;
      object-fit: fill;
      @media (max-width: 900px) {
        padding-right: 0;
      }
    }
  }

  .rightContainer {
    .contactUsTitle {
      font-family: "Playfair Display";
      font-style: normal;
      font-weight: 400;
      font-size: 90px;
      flex-wrap: nowrap;
      color: #000000;
      @media (max-width: 900px) {
        font-size: 70px;
        margin: 20px 0;
      }
    }

    .contactTypeContainer {
      .labelContainer {
        margin-bottom: 30px;
        @media (max-width: 900px) {
          margin-bottom: 0;
        }
        .radio {
          margin-top: 30px;
          @media (max-width: 900px) {
            margin-top: 0;
            margin-bottom: 10px;
          }
        }
        @media (max-width: 900px) {
          &:last-child {
            margin-bottom: 30px;
          }
        }
        .pdRadio {
          @media (max-width: 900px) {
            margin-top: 30px;
          }
        }

        .labelName {
          cursor: pointer;
          padding-left: 10px;
          font-family: "SF Pro Text";
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 20px;
          color: #000000;
          @media (max-width: 900px) {
            padding-left: 0;
          }
        }
      }
    }

    .placeholder {
      font-family: "SF Pro Text";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 120%;
      color: #000000;
    }
    .inputField {
      background: #f2ede6;
      width: 100%;
      outline: none;
      border: none;
      padding: 10px;
      padding-bottom: 8px;

      margin-bottom: 30px;

      border-bottom: 3px solid #000000;
      font-family: "SF Pro Text";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 120%;
      color: #000000;
    }

    .contactButton {
      outline: none;
      border: none;
      cursor: pointer;
      width: 100%;
      background: #e02828;
      border-radius: 100px;
      margin-top: 25px;
      padding: 10px 0 10px 0;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      color: #ffffff;
    }
  }
}

.rentCardContainer {
  width: 70%;
  margin: 0 auto;
  .topContainer {
    .rentImg {
    }

    .rentTitle {
      font-family: "SF Pro Text";
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 36px;
      letter-spacing: -1.25641px;
      color: #000000;
    }
    .rentLoc {
      font-family: "SF Pro Text";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.502564px;
      color: #000000;
    }
    .rentCost {
      font-family: "SF Pro Text";
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 36px;
      letter-spacing: -1.25641px;
      color: #000000;
    }
    .rentBHKSqFt {
      font-family: "SF Pro Text";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.502564px;
    }
    .rentDesc {
      font-family: "SF Pro Text";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.502564px;
      color: #000000;
    }
  }

  .bottomContainer {
    .rentInterestedBtn {
      padding: 12px 16px;
      border: none;
      height: 47px;
      width: 100%;
      cursor: pointer;
      background: #1c3988;
      border-radius: 8px;
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 23px;
      color: #ffffff;
      &:hover {
        background: cadetblue;
      }
    }
    .rentShortListBtn {
      border: none;
      background: none;
      padding: 12px 16px;
      width: 53px;
      background: rgba(77, 223, 253, 0.2);
      border-radius: 8px;
    }
  }
}
