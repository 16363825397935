.vl {
    border-left: 1px solid rgb(0, 0, 0);
    height: 4rem;
    margin-right: 2rem;
    margin-left: 1rem;

    @media (max-width:600px) {
        border-top: 1px solid rgb(0, 0, 0);
        width: 4rem;
        border-left: 0px;
        height: 0rem;
        margin: 1rem;
    }
}

.feeDetials {
    backface-visibility: 33%;
    background: rgba(224, 236, 253, 0.3);
    border-radius: 20px;
    height: 72vh;
    width: 65vw !important;
    position: relative;
    right: -60px;
    bottom: 5%;
    margin-top: 1rem;

    @media (max-width:600px) {
        width: 100vw !important;
        right: 0px;
    }
}

.feeIcon {
    margin-left: 2rem;
    margin-right: 3rem;
    width: 6rem;
    height: 6rem;

    @media (max-width:600px) {
        width: 5rem;
        height: 5rem;
    }
}

// .feeHome {
//     .navHomeBodyContainer {
//         position: relative;
//     }
// }

.feeProperty {
    font-family: Manrope;
    font-style: normal;
    font-weight: 600;
    font-Size: 2rem;
    line-height: 3rem;
    color: #000000;
    text-align: center;
    text-align: left;
    padding-left: 3rem;
    @media (max-width:600px) {
        text-align: center;
        padding-left: 0rem;
    }
}