.vContainer {
    padding: 20px;
    background: #ffffff;
    border-radius: 24px;
    &:hover {
        border: 1px solid #1dabf2;
        box-sizing: border-box;
        box-shadow: 0px 30px 60px -15px rgba(143, 144, 188, 0.15);
        backdrop-filter: blur(20px);
    }
    #vImgContainer {
        padding-top: 0;
        width: 100%;
        .vImg {
            width: 100%;
            object-fit: cover;
            box-shadow: 0px 6px 20px -4px rgba(55, 74, 89, 0.36);
            border-radius: 16px;
        }
    }

    .vTitle {
        height: 31px;
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 31px;
        color: #081f32;
    }
    .vShortDesc {
        height: 17px;
        font-family: "SF Pro Text";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #374a59;
    }
    .vFeatureContainer {
        .vFeatureDiv {
            display: flex;
            align-items: center;
            height: 23px;
            background: #eff8ff;
            border-radius: 4px;
            padding: 4px;
            .vFeatureIcon {
            }
            .vFeatureData {
                margin-left: 4px;
                height: 12px;
                font-family: "Roboto";
                font-style: normal;
                font-weight: 400;
                font-size: 10px;
                line-height: 12px;
                text-align: center;
                color: #000000;
            }
        }
    }
    .vBigDesc {
        font-family: "SF Pro Text";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #374a59;
        width: 95%;
    }
    .vPrice {
        height: 29px;
        font-family: "SF Pro Text";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        color: #081f32;
    }

    .vInterestedBtn {
        padding: 12px 16px;
        border: none;
        height: 47px;
        width: 100%;
        cursor: pointer;
        background: #1c3988;
        border-radius: 8px;

        font-family: "DM Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 23px;
        color: #ffffff;
    }
    .vShortListBtn {
        justify-self: flex-end;
        border: none;
        background: none;
        padding: 12px 16px;
        width: 53px;
        height: 47px;
        background: rgba(77, 223, 253, 0.2);
        border-radius: 8px;
    }
}

.hContainer {
    padding: 20px;
    background: #ffffff;
    border-radius: 16px;
    margin-bottom: 20px;
    .hleftContainer {
        .hImg {
            width: 100%;
            height: 100%;
            object-fit: cover;
            @media (max-width: 900px) {
                height: 200px;
            }
        }
    }

    .hrightContainer {
        .hTitle {
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            color: #6e798c;
        }
        .hNearBy {
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            text-align: right;
            text-decoration-line: underline;
            color: #007ae9;
        }
        .hSHortDesc {
            font-family: "SF Pro Text";
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 46px;
            color: #081f32;
        }
        .hLoc {
            font-family: "SF Pro Text";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #374a59;
        }

        .hFeatureContainer {
            .hFeatureDiv {
                display: flex;
                align-items: center;
                border: 1px solid #eff8ff;
                border-radius: 4px;
                padding: 6px;
                margin-bottom: 6px;
                .hFeatureIcon {
                }
                .hFeatureData {
                    margin-left: 5px;
                    font-family: "Roboto";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                    text-align: center;
                    color: #000000;
                }
            }
        }

        .hFeatureValue {
            font-family: "SF Pro Text";
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 21px;
            color: #081f32;
            text-align: center;
        }
        .hFeatureSub {
            font-family: "SF Pro Text";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #081f32;
            text-align: center;
        }

        .hInterestedBtn {
            padding: 12px 16px;
            border: none;
            height: 47px;
            width: 100%;
            cursor: pointer;
            background: #1c3988;
            border-radius: 8px;

            font-family: "DM Sans";
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 23px;
            color: #ffffff;
            &:hover {
                background: cadetblue;
            }
        }

        .hShortListBtn {
            border: none;
            background: none;
            padding: 12px 16px;
            width: 53px;
            background: rgba(77, 223, 253, 0.2);
            border-radius: 8px;
        }
    }
}
