@import url(//db.onlinewebfonts.com/c/1c45e28f8e86cc89876f003b953cc3e9?family=SF+Pro+Text);
*{
    box-sizing: border-box;
    padding: 0;
    margin:0;
}

.waicon { 
    &:hover {
        cursor: pointer;
    }
}

@mixin rings($duration, $delay) {
    opacity: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -8px;
    left: -8px;
    right: 0;
    bottom: 0;
    content: '';
    height: 100%;
    width: 100%;
    border: 8px solid rgba(0,0,0,0.2);
    border-radius: 100%;
    animation-name: ripple;
    animation-duration: $duration;
    animation-delay: $delay;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(.65,0,.34,1);
    z-index: -1;
}

.waicon {
  align-items: center;
  height: 150px;
  width: 150px;
  border-radius: 100%;
  background: #FFFFFF;
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.25);
  
  span {
    position: relative;
    font-size: 72px;
    top: 5px;
    left: -5px;
  }
  
  &::after {
    @include rings(3s, 0s);
  }
  
  &::before {
    @include rings(3s, 0.5s);
  }
}


@keyframes ripple {
  from {
    opacity: 1;
    transform: scale3d(0.75,0.75,1);
  }
  
  to {
    opacity: 0;
    transform: scale3d(1.5,1.5,1);
  }
}

.loading-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // width: 10vw;
  height: 100vh;
  background-color: white;
}
