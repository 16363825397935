@import '../variables.scss';

* {
    box-sizing: border-box;
}

.cityBox {
    width: 278px;
    height: 426px;

    .cityImage {
        background-repeat: no-repeat;
        background-position: center;
        height: 422px;
        border-radius: 30px;
        text-align: center;
    }

    .cityName {
        margin: $default-margin;
        width: 140px;
        position: relative;
        top: 49px;
        @include fonts('SF Pro Text', normal, 600, 24px, 29px);
        letter-spacing: 0.2em;
        text-transform: uppercase;
        color: $primary-color;
    }
}

.couseBox {
    width: 310px;
    height: 74vh;
}

.howitworks-box {
    height: 263px;
    width: 120px;

    .howitworks-fill {
        margin: $default-margin;
        width: 90px;
        height: 90px;
        box-shadow: 0px 100px 80px rgba(150, 114, 255, 0.07),
            0px 64.8148px 46.8519px rgba(150, 114, 255, 0.0531481),
            0px 38.5185px 25.4815px rgba(150, 114, 255, 0.0425185),
            0px 20px 13px rgba(150, 114, 255, 0.035),
            0px 8.14815px 6.51852px rgba(150, 114, 255, 0.0274815),
            0px 1.85185px 3.14815px rgba(150, 114, 255, 0.0168519);
        border-radius: 20px;

        .howitworks-icon {
            padding-top: 16px;
            padding-bottom: 32px;
            padding-right: 22px;
        }
    }

    .howitworks-title {
        margin: $default-margin;
        position: relative;
        top: 50px;
        @include fonts('Manrope', normal, 600, 1rem, 20px);
        text-align: center;
        color: $default-color;
        width: 120px;
    }

    .howitworks-info {
        position: relative;
        top: 65px;
        margin: $default-margin;
        width: 260px;
        @include fonts('Roboto', normal, 400, 16px, 26px);
        text-align: center;
        color: #333333;
        word-spacing: 4px;
    }
}