* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}
body {
    background: #F3F3FA;
}
.container {
    width: 95%;
    margin: 0 auto;
    background: white;
}
