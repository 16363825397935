@import url(//db.onlinewebfonts.com/c/1c45e28f8e86cc89876f003b953cc3e9?family=SF+Pro+Text);
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,700;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: none;
}

.cardsSectionContainer {
    background: #dce9f880;
    .leftAndRightContainer {
        width: 95%;
        margin: 0 auto;
        padding-top: 30px;
        flex-wrap: nowrap;
        @media (max-width: 900px) {
            width: 90%;
        }
        .leftContainer {
            padding-right: 10px;
            @media (max-width: 900px) {
                padding-right: 0;
            }
            //line 1
            .aboutPropsListContainer {
                .noOfPropsContainer {
                    @media (max-width: 900px) {
                        order: 2;
                    }
                    .noOfProps {
                        font-family: "SF Pro Text";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        color: #141b2d80;
                        @media (max-width: 900px) {
                            line-height: 17px;
                        }
                    }
                }
                .lastUpdateContainer {
                    @media (max-width: 900px) {
                        order: 1;
                    }
                    .lastUpdate {
                        text-align: end;
                        font-family: "SF Pro Text";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 34px;
                        color: #141b2d;
                        @media (max-width: 900px) {
                            line-height: 19px;
                        }
                    }
                }
            }

            //line 2
            .preferenceAndSortContainer {
                //flex-wrap: nowrap;
                overflow-x: scroll;
                width: 100%;
                &::-webkit-scrollbar {
                    display: none;
                }
                .preferenceContainer {
                    button {
                        display: flex;
                        align-items: center;
                        background: #ffffff;
                        border: 1px solid #141b2d;
                        box-sizing: border-box;
                        border-radius: 8px;
                        cursor: pointer;
                        padding: 8px;
                        margin-right: 8px;
                        height: 33px;
                        &:hover {
                            border-color: #1daeff;
                        }
                        &:active {
                            border-color: #1daeff;
                        }

                        .checkIcon {
                            margin-right: 9px;
                        }
                        .checkText {
                            // white-space: nowrap;
                            font-family: "SF Pro Text";
                            font-style: normal;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 17px;
                        }
                    }
                }
                .sortContainer {
                    width: 100%;
                    margin-left: 20px;
                    .sortBtn {
                        background: #ffffff;
                        border: 0.5px solid rgba(0, 0, 0, 0.1);
                        box-sizing: border-box;
                        border-radius: 5px;
                        height: 32px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        padding: 8px;
                        .sortText {
                            // white-space: nowrap;
                            font-family: "SF Pro Text";
                            font-style: normal;
                            font-weight: 300;
                            font-size: 14px;
                            line-height: 17px;
                            color: #000000;
                            margin-right: 30px;
                        }
                        .sortIcon {
                        }
                    }
                }
            }

            .appliedFilters{
                .filterChip{
                    background-color: #ffffff;
                    border: 1px solid #141b2d;
                    box-sizing: border-box;
                    border-radius: 8px;
                    font-family: 'SF Pro Text';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                    color: #141B2D;
                }
                .all{
                    border: 1px solid #1DAEFF;
                }
            }
        }

        //right container
        .rightContainer {
            padding-left: 10px;
            @media (max-width: 900px) {
                padding-left: 0;
            }
            .shortListedTitle {
                font-family: "SF Pro Text";
                font-style: normal;
                font-weight: 500;
                font-size: 28px;
                line-height: 30px;
                color: #141b2d;
                margin-bottom: 35px;
            }
            .compareBtn {
                outline: none;
                padding: 10px;
                background: #716f6ff8;
                opacity: 0.5;
                border: 1px solid #141b2d;
                border-radius: 8px;

                font-family: "SF Pro Text";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                text-align: center;
                color: #141b2d;

                &:enabled {
                    background: #ffffff;
                    cursor: pointer;
                }
            }
            .noShortListContainer {
                padding: 0 15px 0 15px;
                background: #ffffff;
                border-radius: 20px;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                .noShortListImg {
                    width: 100%;
                    object-fit: cover;
                }
                .noShortListTitle {
                    text-align: center;
                    font-family: "SF Pro Text";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 19px;
                    color: #081f32;
                }

                .noShortListDesc {
                    padding-bottom: 15px;
                    font-family: "DM Sans";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 18px;
                    color: #081f32;
                }
                .heartIconContainer {
                    height: 18px;
                    padding: 5px 7px 5px 7px;
                    margin: 0 4px 0 4px;
                    background: #ecfdf3;
                    border-radius: 8px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    .heartIcon {
                        width: 10px;
                        height: 12px;
                    }
                }
            }
        }
    }
}
