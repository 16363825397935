@import url(//db.onlinewebfonts.com/c/1c45e28f8e86cc89876f003b953cc3e9?family=SF+Pro+Text);

.spContainer {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 4.5px;
    padding-left: 6px;
    width: 100%;
    margin-bottom: 20px;
    position: relative;

    .spLeftContainer {
        display: flex;
        align-items: center;
        padding: 5px;
        .spImg {
            width: 100%;
            height: 100%;
            border-radius: 20px;
            object-fit: cover;
        }
    }

    .spRightContainer {
        padding: 5px;
        .spDesc {
            font-family: "SF Pro Text";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #141b2d;
        }
        .spCancelIcon {
            cursor: pointer;
        }

        .spLoc {
            font-family: "SF Pro Text";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #6e798c;
        }

        .spFeatureContainer {
            .spFeature {
                font-family: "SF Pro Text";
                font-style: normal;
                font-weight: 700;
                font-size: 12px;
                line-height: 14px;
                color: #081f32;
            }
            .spFeatureSub {
                font-family: "SF Pro Text";
                font-style: normal;
                font-weight: 400;
                font-size: 10px;
                line-height: 12px;
                color: #081f32;
            }
        }
    }
}

input[type="checkbox"] + label {
    position: absolute;
    top: 40%;
    left: -3%;
}

input[type="checkbox"] {
    position: absolute;
    width: 20px;
    top: 42%;
    left: -3%;
    z-index: 20;
    opacity: 0;
    cursor: pointer;
}

input[type="checkbox"] + label:before {
    content: "";
    background: #ffffff;
    border: 1px solid rgba(8, 31, 50, 0.5);
    border-radius: 100px;
    display: inline-block;
    width: 1em;
    height: 1em;
    transition: 0.2s;
}

input[type="checkbox"] + label:active:before {
    transform: scale(0);
    background: #000000;
}

input[type="checkbox"]:checked + label:before {
    background-color: #0f0d0d;
}
