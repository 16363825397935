$background-color1: #F3F3FA;
$background-color1-mobile: #F4F4F4;
$background-color2: #FFFFFF;
$secondary-color1:#141B2D;
$secondary-color2: #8F90A6;
$default-border-radius: 8px;

@mixin fonts($family,$style,$weight,$size,$line-height){
    font-family: $family;
    font-style: $style;
    font-weight: $weight;
    font-size: $size;
    line-height: $line-height;
}

@mixin default-font{
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

@mixin secondary-font{
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
}

@mixin flex-containers($direction){
    display: flex;
    flex-direction: $direction;
    align-items: center;
    justify-content: center;
}