$default-margin:0px auto;
$default-color:#000000;
$primary-color:#110229;
$secondary-color: #1C3988;

@mixin fonts($family,$style,$weight,$size,$lineHeight){
    font-family: $family;
    font-style: $style;
    font-weight: $weight;
    font-size: $size;
    line-height: $lineHeight;
}

@mixin custom-grids($templateColumns,$templateRows,$templateAreas){
    display: grid;
    grid-template-columns: $templateColumns;
    grid-template-rows: $templateRows;
    grid-template-areas: $templateAreas;
}
