@import './variables.scss';

.heading{
    @include component-headers;
}

.content{
    @include component-content;
}

.pathBoxContainer{
    width:100%;
    height: 16px;
    @include default-fonts;
    color: $primary-color2;
    margin:0 auto;
    margin-top:20px;
}

.galleryContainer{
    @include forSaleContent-containers;
    width:100%;
    height:auto;
    padding-bottom: 22px;

    .leftAndRightContainer{
        width:100% !important;
        
        .leftContainer{
            width:100%;
        }

        .rightContainer{
            padding-left: 20px;
            padding-right: 20px;
            margin:auto;

            .propertyHeader{
                height: 31px;
                margin-top: 10px;
                @include other-fonts('DM Sans',normal,400,24px,31px);
                color: $gallery-color;
                width:100%;
            }
            .propertyDescription{
                margin-top: 8px;
                width:100%;
                @include other-fonts('SF Pro Text',normal,700,24px,29px);
                color: #374A59;

            }
            .listingID{
                margin-top:8px;
                width:100%;
                @include other-fonts('SF Pro Text',normal,400,14px,17px);
                color: $primary-color1;
            }
            .propertyFeatures{
                width:100%;
                margin-top:8px;

                .featureItem{
                    @include flex-containers(row,center);
                    padding-top: 16px;
                    padding-bottom: 16px;

                    @media (max-width:960px){
                        padding-top: 4px;
                        padding-bottom: 4px;
                    }
                    .featureIcon{
                        height:14px;
                    }
                    .featureDescr{
                        margin-left: 4px;
                        @include default-fonts;
                        color: $primary-color1;
                    }
                }
            }
            .interestedBuyers{
                width:100%;
                margin-top:16px;
                @include default-fonts;
                font-size: 10px;
                line-height: 12px;
                color: #9672FF;
            }
            .propertyDetails{
                width:100%;
                margin-top: 16px;
                @include flex-containers(row,center);
                padding: 8px 0px;

                .detailsItem{
                    @include flex-containers(column,center);
                    align-items: center;
                    padding:0px 10px;

                    .detailsValue{
                        @include other-fonts('SF Pro Text',normal,700,18px,21px);
                        color: $gallery-color;
                    }

                    .detailsDesc{
                        @include other-fonts('SF Pro Text',normal,400,12px,14px);
                        color: $gallery-color;
                    }
                }

                .divider{
                    background-color:$divider-color !important;
                }
            }
            .interestedButton{
                width:95%;
                height: 47px;
                @include flex-containers(row,center);
                justify-content: center;
                margin:0px auto;
                margin-top: 16px;              
                background: $secondary-color;
                color: #FFFFFF;
                @include other-fonts('DM Sans',normal,600,18px,23px);
                border-radius: 8px;
                cursor: pointer;
            }
        }
    }
}

.keyHighlightsComponent{
    @include forSaleContent-containers;

    .content{
        color: $primary-color2;
    }
} 

.generalInfoContainer{
    @include forSaleContent-containers;

    .content{
        .infoItem{
            padding-bottom: 15px;
        }

        .infoName{
            @include default-fonts;
            color: $primary-color2;
        }

        .infoValue{
            @include default-fonts;
            color: $primary-color1;
        }

        .highlightedInfo{
            color: #E02828;
        }
    }
}

.amenitiesContainer{
    @include forSaleContent-containers;

    .content{
        .amenitiesHeading{
            @include default-fonts;
            font-size: 18px;
            line-height: 21px;
            color: $primary-color1;
            padding-bottom: 15px;
        }

        .amenitiesItem{
            @include default-fonts;
            color: $primary-color2;
            padding-bottom: 5px;
            
            .tickMark{
                padding-right:10px;
            }
        }
    }
}

.locationInfoContainer{
    @include forSaleContent-containers;
    padding-bottom: 20px;

    .content{
        .mapImage{
            width:100%;
            z-index:-1000;
        }

        .zoomButtons{
            position: relative;
            margin-top: -90px;
            margin-left: 95%;

            @media (max-width:960px){
                margin-left: 93%;
            }
            
            .zoominButton{
                z-index: 1000;
                width:30px;
                height:30px;
                background: #FFFFFF;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                border-radius: 5px;
                @include flex-containers(column,center);
                justify-content: center;
                font-size:24px;
                cursor: pointer;
            }

            .zoomoutButton{
                width:30px;
                height:30px;
                margin-top:10px;
                background: #FFFFFF;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                border-radius: 5px;
                display: flex;
                @include flex-containers(column,center);
                font-size:24px;
                cursor: pointer;
            }
        }
    }
}

.priceChangesContainer{
    @include forSaleContent-containers;

    .subHeading{
        margin-left:auto;
        padding-right:10px;
        @include default-fonts;
    }

    @media (max-width:600px){
        .heading{
            display: unset;
            padding-top: 10px;
            padding-bottom: 14px;
        }
        .subHeading{
            padding-top: 4px;
        }
    }

    .content{
        .priceGrid{
            margin-top: -20px;

            .priceCategory{
                @include default-fonts;
                color: $primary-color1;
                margin-top:20px;
            }
    
            .yearsTab{
                @include flex-containers(row,center);
                padding: 0px;
                gap: 28px;
                margin-top:24px;
        
                .yearItem{
                    @include default-fonts;
                    color: $primary-color2;
                    padding:5px;
                    cursor: pointer;
                }
        
                .activeSalesItem{
                    color: $sales-price-color;
                    border: 1px solid $sales-price-color;
                    border-radius: 5px;
                }
        
                .activeRentalItem{
                    color:$rental-price-color;
                    border:1px solid $rental-price-color;
                    border-radius: 5px;
                }
            }
        }
    }

    .graphContainer{
        margin-top:24.5px;
        .graphItem{
            margin-bottom:20px;
            .streetNumber{
                @include default-fonts;
                margin-bottom: 10px;
            }
        }
    }

    .graphParent{
        height: 25px;
        width: 90%;
        border-radius: 5px;
        overflow: hidden;

        .graphChild{
            height:100%;
            border-radius:5px;
            text-align: right;

            .graphText{
                @include default-fonts;
                color: #FFFFFF;
                margin-right:8px;
            }
        }
        .sales{
            background-color: $sales-price-color;
        }
        .rental{
            background-color: $rental-price-color;
        }
    }
    .sales{
        border: 1px solid $sales-price-color;
    }
    .rental{
        border: 1px solid $rental-price-color;

    }
}

.activityContainer{
    @include forSaleContent-containers;

    .content{
        .activityDetails{
            padding:18.5px 20px;

            .activityItem{
                @include flex-containers(column,center);
                align-items: center;
        
                .activityValue{
                    @include other-fonts('SF Pro Text',normal,700,32px,38px);
                    color: #081F32;
                }
        
                .activityDesc{
                    @include other-fonts('SF Pro Text',normal,400,12px,14px);
                    color: #081F32;
                }
            }

            .divider{
                background-color:$divider-color !important;
            }
        }
    }
}